export const GET_ALL_CUSTOMER_LOADING = "GET_ALL_CUSTOMER_LOADING"
export const GET_ALL_CUSTOMER_SUCCESS = "GET_ALL_CUSTOMER_SUCCESS"
export const GET_ALL_CUSTOMER_ERROR = "GET_ALL_CUSTOMER_ERROR"

// get delte the customer 

export const GET_CUSTOMER_DELETE_LOADING = 'GET_CUSTOMER_DELETE_LOADING';
export const GET_CUSTOMER_DELETE_SUCCESS = 'GET_CUSTOMER_DELETE_SUCCESS';
export const GET_CUSTOMER_DELETE_ERROR = 'GET_CUSTOMER_DELETE_ERROR';


// get customer update
export const GET_CUSTOMER_UPDATE_LOADING = 'GET_CUSTOMER_UPDATE_LOADING';
export const GET_CUSTOMER_UPDATE_SUCCESS = 'GET_CUSTOMER_UPDATE_SUCCESS';
export const GET_CUSTOMER_UPDATE_ERROR = 'GET_CUSTOMER_UPDATE_ERROR' ;


export const GET_CUSTOMER_CREATE_LOADING = 'GET_CUSTOMER_UPDATE_LOADING'
export const GET_CUSTOMER_CREATE_SUCCESS='GET_CUSTOMER_CREATE_SUCCESS'
export const GET_CUSTOMER_CREATE_ERROR='GET_CUSTOMER_CREATE_ERROR'


export const GET_ALL_Enquiry_LOADING = "GET_ALL_Enquiry_LOADING"
export const GET_ALL_Enquiry_SUCCESS = "GET_ALL_Enquiry_SUCCESS"
export const GET_ALL_Enquiry_ERROR = "GET_ALL_Enquiry_ERROR"



export const GET_ALL_Members_LOADING = "GET_ALL_Members_LOADING"
export const GET_ALL_Members_SUCCESS = "GET_ALL_Members_SUCCESS"
export const GET_ALL_Members_ERROR = "GET_ALL_Members_ERROR"


export const GET_ALL_LASTSERVICES_LOADING = "GET_ALL_LASTSERVICES_LOADING"
export const GET_ALL_LASTSERVICES_SUCCESS = "GET_ALL_LASTSERVICES_SUCCESS"
export const GET_ALL_LASTSERVICES_ERROR = "GET_ALL_LASTSERVICES_ERROR"


