export const EMPLOYEE_REG_API_LOADING = "EMPLOYEE_REG_API_LOADING"
export const EMPLOYEE_REG_API_SUCCESS = "EMPLOYEE_REG_API_SUCCESS"
export const EMPLOYEE_REG_API_ERROR = "EMPLOYEE_REG_API_ERROR"



// GET ALL DATA

export const ALL_EMPLOYEE_API_LOADING = "ALL_EMPLOYEE_API_LOADING"
export const ALL_EMPLOYEE_API_SUCCESS = "ALL_EMPLOYEE_API_SUCCESS"
export const ALL_EMPLOYEE_ERROR = "ALL_EMPLOYEE_ERROR"